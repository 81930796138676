import { Card, i18n, Tab, Tabs, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import DiscardDraftModal from '@/components/DiscardDraftModal';
import { useFlags } from '@/featureFlags/useFlags';
import { useMst } from '@/mobx-models/Root';
import useInspectionResults, {
  InspectionResultItem,
} from '@/rxdb/InspectionResults/useInspectionResults';
import { useNotification } from '@/utilities';

import AssignedEquipment from './AssignedEquipment';
import CompleteWOTab from './CompleteWOTab';
import DeficienciesChecksTab from './DeficienciesChecksTab';
import DeficienciesTab from './DeficienciesTab';
import useStyles from './Equipment.styles';
import EquipmentTab from './EquipmentTab';

const Equipment: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const { equipmentTabVisible } = useFlags();

  const [tabIndex, setTabIndex] = useState(0);
  const [pendingTabIndex, setPendingTabIndex] = useState<number | null>(null);
  const [equipmentPreOpChecks, setEquipmentPreOpChecks] = useState<InspectionResultItem[]>([]);
  const [equipmentIsLoading, setEquipmentIsLoading] = useState(true);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);

  const { listPreOpChecksThisShift, inspectionsInitialized, inspectionResultsCollection } =
    useInspectionResults();
  const { errorNotification } = useNotification();

  const tabProperties = useMemo(
    () => [
      { label: i18n.t('Deficiencies'), key: 'deficienciesTab' },
      { label: i18n.t('Checks this shift'), key: 'checksTab' },
      { label: i18n.t('Complete WOs'), key: 'woTab' },
      equipmentTabVisible
        ? { label: i18n.t('Equipment'), key: 'equipmentTab' }
        : { label: i18n.t('Assigned equipment'), key: 'assignedEq' },
    ],
    [equipmentTabVisible],
  );

  const onTabChanged = (event: React.ChangeEvent<unknown>, newValue: number) => {
    if (editIndex !== null) {
      setPendingTabIndex(newValue);
      setDiscardOpen(true);
    } else {
      setTabIndex(newValue);
    }
  };

  const getPropChecks = async () => {
    setEquipmentIsLoading(true);
    try {
      setEquipmentPreOpChecks(await listPreOpChecksThisShift());
    } catch (error) {
      console.log('🚀 ~ file: PreOpCheckCard.tsx ~ line 65 ~ getPropChecks ~ error', error);
      errorNotification('Unable to load checks this shift.');
    }
    setTimeout(() => {
      setEquipmentIsLoading(false);
    }, 200);
  };

  const subscribeToPreOpChecks = () => {
    if (inspectionResultsCollection) {
      inspectionResultsCollection.$.subscribe(() => {
        getPropChecks();
      });
    }
  };

  useEffect(() => {
    if (inspectionsInitialized) {
      getPropChecks();
      subscribeToPreOpChecks();
    }
  }, [inspectionsInitialized, shiftPicker.Date, shiftPicker.Type, shiftPicker.currentBorerShiftId]);

  const getUIForTab = useMemo(() => {
    switch (tabIndex) {
      case 0:
        return <DeficienciesTab />;
      case 1:
        return (
          <DeficienciesChecksTab
            equipmentPreOpChecks={equipmentPreOpChecks}
            equipmentIsLoading={equipmentIsLoading}
          />
        );
      case 2:
        return <CompleteWOTab />;
      case 3:
        return equipmentTabVisible ? (
          <EquipmentTab editIndex={editIndex} setEditIndex={setEditIndex} />
        ) : (
          <AssignedEquipment />
        );
      default:
        return (
          <div>
            <Typography>Unknown Tab</Typography>
          </div>
        );
    }
  }, [
    tabIndex,
    equipmentPreOpChecks,
    equipmentIsLoading,
    editIndex,
    setEditIndex,
    equipmentTabVisible,
  ]);

  return (
    <div className={classes.root}>
      <Card className={classes.tabCard}>
        <Tabs value={tabIndex} variant="standard" onChange={onTabChanged}>
          {tabProperties.map(tab => (
            <Tab
              label={
                tab.key === 'checksTab'
                  ? `${tab.label} (${equipmentPreOpChecks.length})`
                  : tab.label
              }
              key={tab.key}
            />
          ))}
        </Tabs>
      </Card>
      <div>{getUIForTab}</div>
      <DiscardDraftModal
        open={discardOpen}
        onCancel={() => {
          setDiscardOpen(false);
        }}
        onDiscard={() => {
          setDiscardOpen(false);
          setEditIndex(null);
          if (pendingTabIndex !== null) {
            setTabIndex(pendingTabIndex);
            setPendingTabIndex(null);
          }
          errorNotification(i18n.t('Draft discarded'));
        }}
      />
    </div>
  );
};

export default observer(Equipment);
