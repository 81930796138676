import { Button, i18n, Typography } from '@nutrien/cxp-components';
import React from 'react';

import useStyles from './EquipmentTab.styles';

type ResultNotFoundProps = {
  clearFilterCallback: () => void;
};

const ResultNotFound: React.FC<ResultNotFoundProps> = ({ clearFilterCallback }) => {
  const classes = useStyles();

  return (
    <div className={classes.noDataContainer}>
      <img src="../../../../../no_result_found.svg" alt="No result found" />
      <Typography variant="subtitle1" fontWeight={600}>
        No results found
      </Typography>
      <Typography variant="body1">Please update your search to see results.</Typography>
      <Button
        variant="text"
        color="primary"
        onClick={clearFilterCallback}
        data-cy="clear-filter-button"
        id="clear-filter-button"
        noMinHeight
        className={classes.button}
      >
        {i18n.t('Clear search')}
      </Button>
    </div>
  );
};

export default ResultNotFound;
