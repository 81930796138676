import { ExpansionPanel, i18n } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import GenericRichTextInput from '@/components/GenericRichTextInput/GenericRichTextInput';
import GenericSidePanel from '@/components/GenericSidePanel';
import { useMst } from '@/mobx-models/Root';
import { ExtendedBorerShiftComment } from '@/rxdb/BorerShiftComment/queryBuilder';
import useBorerShiftComment, {
  ExtendedBorerShiftCommentMap,
} from '@/rxdb/BorerShiftComment/useBorerShiftComment';
import { getUnixMillisecondTimestamp } from '@/rxdb/rxdbUtilityFunctions';
import { useNotification } from '@/utilities';
import { EMPTY_GUID, EMPTY_HTML_COMMENT } from '@/utilities/constants';
import { DisplayInBorer } from '@/utilities/enums';
import { toKebabCase } from '@/utilities/utilityFunctions';

import useStyles from './ModifyCommentSidePanel.styles';

interface Props {
  open: boolean;
  setSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  commentsToEdit: ExtendedBorerShiftCommentMap;
  loading: boolean;
}

const generateDefaultComment = () => ({
  borerShiftCommentTypeId: EMPTY_GUID,
  borerShiftId: '',
  comment: '',
  id: uuid(),
  isDeleted: false,
  updatedAt: getUnixMillisecondTimestamp(),
  version: 1,
});

interface ExtendedBorerShiftCommentWithHTML extends Partial<ExtendedBorerShiftComment> {
  commentHTML: string;
  initialHTML: string;
  label: string;
}

const ModifyCommentSidePanel: React.FC<Props> = ({
  open,
  setSidePanelOpen,
  commentsToEdit,
  loading,
}: Props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const undo = useRef(false);
  const { upsertBorerShiftComment } = useBorerShiftComment();
  const { successNotification, errorNotification } = useNotification();
  const { shiftPicker } = useMst();
  const [comments, setComments] = useState<ExtendedBorerShiftCommentWithHTML[]>([]);
  const [initialComments, setInitialComments] = useState<ExtendedBorerShiftCommentWithHTML[]>([]);
  const [expanded, setExpanded] = useState<boolean[]>([]);

  const initializeComments = useCallback(() => {
    const cmts: ExtendedBorerShiftCommentWithHTML[] = [];
    Array.from(commentsToEdit.entries()).forEach(([key, value]) => {
      if (value?.displayInBorer !== DisplayInBorer.AllowEdit) return;
      if (value?.comment) {
        cmts.push({
          ...value,
          commentHTML: value?.comment,
          initialHTML: value?.comment,
          label: key as string,
        });
      } else {
        const defaultComment = generateDefaultComment();
        cmts.push({
          ...defaultComment,
          ...value,
          commentHTML: EMPTY_HTML_COMMENT,
          initialHTML: EMPTY_HTML_COMMENT,
          label: key as string,
        });
      }
    });
    setComments(cmts);
    setInitialComments(cmts);
  }, [commentsToEdit]);

  useEffect(() => {
    initializeComments();
  }, [initializeComments]);

  const onClose = useCallback(() => setSidePanelOpen(false), [setSidePanelOpen]);

  const onOpen = useCallback(() => setSidePanelOpen(true), [setSidePanelOpen]);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    try {
      if (shiftPicker?.currentBorerShiftId) {
        await Promise.all(
          comments.map(async comment => {
            if (comment.commentHTML !== comment.initialHTML) {
              await upsertBorerShiftComment({
                borerShiftId: shiftPicker?.currentBorerShiftId as string,
                borerShiftCommentTypeId: comment.borerShiftCommentTypeId as string,
                id: comment.id || uuid(),
                comment: comment.commentHTML,
                version: comment.version || 1,
                updatedAt: getUnixMillisecondTimestamp(),
                isDeleted: false,
              });
            }
          }),
        );

        successNotification(i18n.t('Comments added'));
        onClose();
      }
    } catch (error) {
      console.error(`ERROR saving comment: ${error}`);
      errorNotification(`Could not save comments`);
    }
    setIsSaving(false);
  }, [
    setIsSaving,
    shiftPicker?.currentBorerShiftId,
    errorNotification,
    successNotification,
    onClose,
    comments,
    upsertBorerShiftComment,
  ]);

  const onSetUndo = useCallback(() => {
    undo.current = true;
  }, []);

  useEffect(() => {
    setExpanded(prev => {
      if (open && !undo.current) {
        // If all comments are empty or there is only one comment, expand the first one
        if (
          initialComments
            .map(cmt => cmt.commentHTML === EMPTY_HTML_COMMENT)
            .every(hasComment => hasComment) ||
          initialComments.length === 1
        ) {
          return initialComments.map((val, idx) => (idx === 0 ? true : false));
        }
        // If there are any comments that are not empty, expand them
        return initialComments.map(cmt => cmt.commentHTML !== EMPTY_HTML_COMMENT);
      }
      return prev;
    });
  }, [initialComments, open]);

  useEffect(() => {
    if (open && !undo.current) {
      initializeComments();
    } else if (open) {
      undo.current = false;
    }
  }, [open, initializeComments]);

  const handlePanelChange = useCallback(
    idx => {
      return (event: unknown, isExpanded: boolean) => {
        setExpanded(prev => {
          const newExpanded = [...prev];
          newExpanded[idx] = isExpanded;
          return newExpanded;
        });
      };
    },
    [setExpanded],
  );

  const existingComment = useMemo(() => {
    return comments
      .map(cmt => cmt.commentHTML !== EMPTY_HTML_COMMENT)
      .some(hasExistingComment => hasExistingComment);
  }, [comments]);

  const handleCommentChange = useCallback(
    (idx: number) => {
      return (commentHTML: string) => {
        setComments(prev => {
          const newComments = [...prev];
          newComments[idx].commentHTML = commentHTML;
          return newComments;
        });
      };
    },
    [setComments],
  );

  const hasEdits = useMemo(() => {
    const parser = new DOMParser();

    return comments.some(cmt => {
      const initialHTML = parser.parseFromString(cmt.initialHTML, 'text/html');
      const commentHTML = parser.parseFromString(cmt.commentHTML, 'text/html');
      return (
        commentHTML.documentElement.textContent?.trim() !==
        initialHTML.documentElement.textContent?.trim()
      );
    });
  }, [comments]);

  return (
    <GenericSidePanel
      open={open}
      onClose={onClose}
      title={!existingComment ? i18n.t('Add comment') : i18n.t('Edit comment')}
      onOpen={onOpen}
      hasEdits={hasEdits}
      canSave={hasEdits}
      isSaving={isSaving}
      setUndo={onSetUndo}
      loading={loading}
      onSave={onSave}
      discardNotificationText={i18n.t('Comment draft discarded')}
    >
      {comments.map((cmt, idx) => (
        <ExpansionPanel
          className={classes.panel}
          title={cmt.label}
          key={cmt.borerShiftCommentTypeId}
          data-cy={`edit-comment-panel-${toKebabCase(cmt.label)}`}
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded[idx]}
          onChange={handlePanelChange(idx)}
          expansionPanelDetailProps={{
            classes: { root: classes.panelContent },
          }}
          classes={{
            expanded: classes.panelExpanded,
          }}
          panelContent={
            <GenericRichTextInput
              subtitle={`${cmt.label} (optional)`}
              commentHTML={cmt.commentHTML}
              setCommentHTML={handleCommentChange(idx)}
            />
          }
        />
      ))}
    </GenericSidePanel>
  );
};

export default observer(ModifyCommentSidePanel);
