import { hasPermissionFor, UserPermissionTypes } from '@nutrien/minesight-utility-module';
import { print } from 'graphql';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { gql } from '../../graphql/Client';
import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import MigrationHelper from '../MigrationHelper';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface Equipment extends BaseEntity {
  readonly equipmentTypeId: string;
  readonly blockId: string;
  readonly panelId: string;
  readonly roomId: string;
  readonly shortName: string | null;
  readonly longName: string;
  readonly surveyPointId: string;
  readonly isActive: boolean;
  readonly assignedBorerEquipmentId: string | null;
  readonly comment: string | null;
  readonly equipmentStatusId: string | null;
  readonly tonnesPerScoop: number | null;
}
export type EquipmentCollection = RxCollection<Equipment> | null;
export type EquipmentDocument = RxDocument<Equipment>;

export const equipmentSchema: RxJsonSchema<Equipment> = {
  type: 'object',
  version: 5,
  description: 'describes a Equipment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    equipmentTypeId: { ref: RxdbCollectionName.EQUIPMENT_TYPE, type: 'string' },
    blockId: { ref: RxdbCollectionName.BLOCKS, type: ['string', 'null'] },
    panelId: { ref: RxdbCollectionName.PANELS, type: ['string', 'null'] },
    roomId: { ref: RxdbCollectionName.ROOMS, type: ['string', 'null'] },
    surveyPointId: {
      ref: RxdbCollectionName.SURVEY_POINTS,
      type: ['string', 'null'],
    },
    shortName: { type: ['string', 'null'] },
    longName: { type: 'string' },
    isDeleted: { type: 'boolean' },
    updatedAt: { type: 'number' },
    version: { type: 'number' },
    isActive: { type: 'boolean' },
    assignedBorerEquipmentId: {
      type: ['string', 'null'],
    },
    comment: { type: ['string', 'null'] },
    equipmentStatusId: {
      ref: RxdbCollectionName.EQUIPMENT_STATUS,
      type: ['string', 'null'],
    },
    tonnesPerScoop: { type: ['number', 'null'] },
  },
};

export const equipmentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: EquipmentDocument) => oldDoc,
  2: (oldDoc: EquipmentDocument) => oldDoc,
  3: (oldDoc: EquipmentDocument) => ({
    ...oldDoc,
    assignedBorerEquipmentId: oldDoc.assignedBorerEquipmentId || null,
  }),
  4: (oldDoc: EquipmentDocument) => ({
    ...oldDoc,
    equipmentStatusId: null,
    comment: null,
  }),
  5: async (oldDoc: EquipmentDocument) => {
    if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');
    if (
      oldDoc.isActive === false &&
      !hasPermissionFor(UserPermissionTypes.EQUIPMENT_STATUS_INACTIVE_WRITE)
    ) {
      // Cannot augment data for inactive equipment without permission
      return oldDoc;
    }
    const equipmentInfo = await MigrationHelper.instance.getEquipmentDetails(oldDoc.id);
    const equipmentStatusId = equipmentInfo?.status?.id || null;
    const comment = equipmentInfo?.comment || null;
    const tonnesPerScoop = equipmentInfo?.tonnesPerScoop || null;

    return {
      ...oldDoc,
      tonnesPerScoop,
      comment,
      equipmentStatusId,
    };
  },
};

const equipmentFeedQuery = print(gql`
  query equipmentFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    equipmentFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      id
      equipmentTypeId
      blockId
      panelId
      roomId
      isDeleted
      updatedAt
      version
      shortName
      longName
      surveyPointId
      isActive
      assignedBorerEquipmentId
      comment
      equipmentStatusId
      tonnesPerScoop
    }
  }
`);

export const equipmentPullQueryBuilder = generatePullQuery(
  equipmentFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);

export const updateEquipmentMutation = print(gql`
  mutation updateBorerEquipmentStatusComment($input: UpdateBorerEquipmentStatusCommentInput!) {
    updateBorerEquipmentStatusComment(input: $input) {
      id
      comment
      assignedBorerId
      equipmentStatusId
    }
  }
`);

type UpdateBorerEquipmentStatusCommentInput = {
  id: string;
  comment: string | null;
  equipmentStatusId: string;
  assignedBorerEquipmentId: string | null;
  version: number;
};

const mapEquipmentDocumentToInput = (doc: Equipment): UpdateBorerEquipmentStatusCommentInput => {
  return {
    id: doc.id,
    comment: doc.comment,
    equipmentStatusId: doc.equipmentStatusId ?? '',
    assignedBorerEquipmentId: doc.assignedBorerEquipmentId || null,
    version: doc.version,
  };
};

export const equipmentPushQueryBuilder = generatePushQuery(
  updateEquipmentMutation,
  (doc: Equipment) => ({
    input: mapEquipmentDocumentToInput(doc),
  }),
);
