import { Grid } from '@material-ui/core';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Icons,
  Select,
  TextField,
  Typography,
} from '@nutrien/cxp-components';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFlags } from '@/featureFlags/useFlags';
import useEquipment, { PopulatedEquipment } from '@/rxdb/Equipment/useEquipment';
import { AssignmentType } from '@/utilities/constants';
import { useNotification } from '@/utilities/index';

import useStyles from './EquipmentTab.styles';
import ResultNotFound from './ResultNotFound';

const PAGE_SIZE = 20;
interface EquipmentProps {
  editIndex: number | null;
  setEditIndex: (index: number | null) => void;
}

const EquipmentTab: React.FC<EquipmentProps> = ({ editIndex, setEditIndex }: EquipmentProps) => {
  const classes = useStyles();
  const { activeEquipmentList, assignedEquipmentList, updateEquipment } = useEquipment();
  const { equipmentTabEditable } = useFlags();

  const [onlyAssignedEquipment, setOnlyAssignedEquipment] = useState(false);

  const equipmentList = useMemo(() => {
    return onlyAssignedEquipment ? assignedEquipmentList : activeEquipmentList;
  }, [activeEquipmentList, assignedEquipmentList, onlyAssignedEquipment]);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEquipment, setFilteredEquipment] = useState(equipmentList);
  const [loadedCount, setLoadedCount] = useState(PAGE_SIZE);

  const [visibleEquipment, setVisibleEquipment] = useState<PopulatedEquipment[]>(
    filteredEquipment.slice(0, PAGE_SIZE),
  );
  const [initialValues, setInitialValues] = useState<PopulatedEquipment | null>(null);

  const bottomRef = useRef<HTMLDivElement | null>(null);
  const { successNotification } = useNotification();

  const loadMoreData = useCallback(() => {
    if (loadedCount < filteredEquipment.length) {
      setTimeout(() => {
        setVisibleEquipment(prev => [
          ...prev,
          ...filteredEquipment.slice(loadedCount, loadedCount + PAGE_SIZE),
        ]);
        setLoadedCount(prev => prev + PAGE_SIZE);
      }, 200);
    }
  }, [filteredEquipment, loadedCount]);

  // Infinite scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          loadMoreData();
        }
      },
      { threshold: 0.5 },
    );

    if (bottomRef.current) observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [loadMoreData]);

  // Filter equipment list when searchQuery changes
  useEffect(() => {
    const searchKeyword = searchQuery?.toLowerCase()?.trim();

    const filteredList = equipmentList.filter(equipment => {
      return (
        equipment.equipmentType?.description?.toLowerCase().includes(searchKeyword) ||
        equipment.shortName?.toLowerCase()?.includes(searchKeyword) ||
        equipment.longName?.toLowerCase()?.includes(searchKeyword)
      );
    });
    setFilteredEquipment(filteredList);
    setTimeout(() => {
      setVisibleEquipment(filteredList.slice(0, PAGE_SIZE));
      setLoadedCount(PAGE_SIZE);
    }, 200);
  }, [equipmentList, searchQuery]);

  const { statusOptions, assignmentOptions } = useMemo(() => {
    const uniqueStatuses = new Map();
    const uniqueAssignments: PopulatedEquipment[] = [];
    const unassignedAssignment: PopulatedEquipment = {
      id: null,
      shortName: 'Unassigned',
      longName: 'Unassigned',
    };

    uniqueAssignments.push(unassignedAssignment);

    activeEquipmentList.forEach((equipment: PopulatedEquipment) => {
      if (equipment.equipmentStatus?.id) {
        uniqueStatuses.set(equipment.equipmentStatus.id, equipment.equipmentStatus);
      }
      if (
        [AssignmentType.BORER, AssignmentType.REHAB_MACHINE].includes(
          equipment.equipmentType?.description.toLowerCase() as AssignmentType,
        )
      ) {
        uniqueAssignments.push(equipment);
      }
    });
    return {
      statusOptions: Array.from(uniqueStatuses.values()),
      assignmentOptions: uniqueAssignments,
    };
  }, [activeEquipmentList]);

  const handleUpdateEquipment = (index: number, updatedFields: Partial<PopulatedEquipment>) => {
    setVisibleEquipment(prev =>
      prev.map((equipment, i) => (i === index ? { ...equipment, ...updatedFields } : equipment)),
    );
  };

  const handleSave = async (index: number) => {
    const updatedEquipment = visibleEquipment[index];
    await updateEquipment(updatedEquipment);
    successNotification('Changes saved');
    setEditIndex(null);
    setInitialValues(null);
  };

  const hasChanges = (index: number) => {
    const equipment = visibleEquipment[index];
    if (!initialValues) return false;
    return (
      equipment.equipmentStatusId !== initialValues.equipmentStatusId ||
      equipment.assignedBorerEquipmentId !== initialValues.assignedBorerEquipmentId ||
      equipment.comment !== initialValues.comment
    );
  };

  const handleEdit = (index: number) => {
    const equipment = visibleEquipment[index];
    setInitialValues(equipment);
    setEditIndex(index);
  };

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e?.currentTarget?.value);
  };

  const handleOnAssignedCheck = () => {
    setOnlyAssignedEquipment(prev => !prev);
  };

  const handleCancel = (index: number) => {
    setVisibleEquipment(prev =>
      prev.map((equipment, i) => (i === index ? activeEquipmentList[index] : equipment)),
    );
    setEditIndex(null);
    setInitialValues(null);
  };
  const clearFilterHandler = useCallback(() => {
    setSearchQuery('');
  }, []);

  return (
    <Card elevation={1} className={classes.card}>
      <Grid container>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <TextField
              className={classes.searchText}
              placeholder="Search"
              value={searchQuery}
              onChange={handleOnSearch}
              variant="outlined"
              size="small"
              noMinHeight
              startAdornment={<Icons.SearchFeather color="primary" style={{ marginRight: 3 }} />}
            />
          </Grid>
          <Grid item xs={6} className={classes.assignedCheckboxGrid}>
            <FormControlLabel
              className={classes.assignedCheckbox}
              control={
                <Checkbox
                  onChange={handleOnAssignedCheck}
                  color="primary"
                  checked={onlyAssignedEquipment}
                  css={undefined}
                />
              }
              label="Show only Assigned equipments"
            />
          </Grid>
        </Grid>
        <Grid className={classes.container} item container xs={12} md={12}>
          {equipmentList.length > 0 && filteredEquipment.length === 0 ? (
            <ResultNotFound clearFilterCallback={clearFilterHandler} />
          ) : (
            <>
              <Grid item xs={2} md={2} className={classes.gridItem}>
                <Typography className={classes.header} variant="h6">
                  Type
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} className={classes.gridItem}>
                <Typography className={classes.header} variant="h6">
                  Name
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                className={`${classes.gridItem} ${classes.statusItemHeader}`}
              >
                <Typography className={classes.header} variant="h6">
                  Status
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} className={classes.gridItem}>
                <Typography className={classes.header} variant="h6">
                  Assignment
                </Typography>
              </Grid>
              <Grid
                item
                xs={equipmentTabEditable ? 3 : 4}
                md={equipmentTabEditable ? 3 : 4}
                className={classes.gridItem}
              >
                <Typography className={classes.header} variant="h6">
                  Comments
                </Typography>
              </Grid>
              {equipmentTabEditable && (
                <Grid item xs={1} md={1} className={classes.gridItemAction}>
                  <Typography className={classes.header} variant="h6">
                    Action
                  </Typography>
                </Grid>
              )}

              {visibleEquipment.map((equipment, index: number) => (
                <Grid container item key={`${equipment.equipmentTypeId}-${index}`} xs={12} md={12}>
                  <Grid item xs={2} md={2} className={classes.gridItem}>
                    <Typography
                      className={`${classes.itemSize} ${classes.header}`}
                      fontWeight={600}
                    >
                      {equipment.equipmentType?.description || ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} className={classes.gridItem}>
                    <Typography
                      className={`${classes.itemSize} ${classes.header}`}
                      fontWeight={600}
                    >
                      {equipment.longName || equipment.shortName}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    md={2}
                    className={`${classes.gridItem} ${classes.statusItem} ${
                      editIndex === index ? classes.selectedRow : ''
                    }`}
                  >
                    <Select
                      className={`${classes.statusSelect} ${classes.itemSize} ${classes.header} ${
                        editIndex !== index
                          ? `${classes.readOnlyStatus} ${
                              statusOptions
                                .find(status => status.id === equipment.equipmentStatusId)
                                ?.description?.toLowerCase() === 'up'
                                ? classes.up
                                : classes.down
                            }`
                          : ''
                      } `}
                      variant={editIndex !== index ? 'empty' : 'outlined'}
                      list={statusOptions}
                      getOptionLabel={status => status.description}
                      getOptionValue={status => status}
                      onChange={(event, value) =>
                        handleUpdateEquipment(index, { equipmentStatusId: value.id })
                      }
                      value={statusOptions.find(
                        status => status.id === equipment.equipmentStatusId,
                      )}
                      data-testid={`Equipment-Status-${index}`}
                      css={undefined}
                      hideIconComponent={editIndex !== index ? true : false}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    md={2}
                    className={`${classes.gridItem} ${
                      editIndex === index ? classes.selectedRow : ''
                    }`}
                  >
                    <Select
                      className={`${classes.assignmentSelect} ${classes.itemSize} ${
                        classes.header
                      } ${editIndex !== index ? classes.readOnlyAssignment : ''} `}
                      variant={editIndex !== index ? 'empty' : 'outlined'}
                      list={assignmentOptions}
                      getOptionLabel={assignment => assignment.shortName || assignment.longName}
                      getOptionValue={assignment => assignment}
                      onChange={(event: any, value: any) =>
                        handleUpdateEquipment(index, { assignedBorerEquipmentId: value.id })
                      }
                      value={
                        equipment.assignedBorerEquipmentId || editIndex === index
                          ? assignmentOptions.find(
                              assignment => assignment.id === equipment.assignedBorerEquipmentId,
                            )
                          : null
                      }
                      data-testid={`Equipment-Assignment-${index}`}
                      css={undefined}
                      hideIconComponent={editIndex !== index ? true : false}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={equipmentTabEditable ? 3 : 4}
                    md={equipmentTabEditable ? 3 : 4}
                    className={`${classes.gridItem} ${
                      editIndex === index ? classes.selectedRow : ''
                    }`}
                    style={{ paddingRight: '10px' }}
                  >
                    <TextField
                      className={`${classes.header} ${classes.comment} ${
                        editIndex !== index ? classes.readOnlyInput : ''
                      }`}
                      onChange={e => handleUpdateEquipment(index, { comment: e.target.value })}
                      value={equipment.comment ?? ''}
                      data-cy="equipment-comment"
                      multiline
                      label={null}
                      InputLabelProps={{
                        shrink: true,
                        style: { height: 0, visibility: 'hidden', padding: 0, margin: 0 },
                      }}
                      readOnlyInput={editIndex !== index}
                      size="small"
                    />
                  </Grid>
                  {equipmentTabEditable && (
                    <Grid
                      item
                      xs={1}
                      md={1}
                      className={`${classes.gridItemAction} ${
                        editIndex === index ? classes.selectedRow : ''
                      }`}
                    >
                      {editIndex === index ? (
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '60%',
                          }}
                        >
                          <Icons.CheckCircleIcon
                            color={hasChanges(index) ? 'primary' : 'disabled'}
                            onClick={() => {
                              if (hasChanges(index)) handleSave(index);
                            }}
                          />
                          <Icons.XCircleFeather color="error" onClick={() => handleCancel(index)} />
                        </Grid>
                      ) : (
                        <Icons.EditFeather
                          color={editIndex === index || editIndex === null ? 'primary' : 'disabled'}
                          onClick={editIndex === null ? () => handleEdit(index) : undefined}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              ))}
              <Grid
                item
                container
                ref={bottomRef}
                data-testid="bottom-scroll"
                style={{ height: '30px', width: '100%', marginBottom: '30px' }}
              ></Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default EquipmentTab;
