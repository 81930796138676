import { Grid } from '@material-ui/core';
import { IconButton, Icons, Typography } from '@nutrien/cxp-components';
import { translate } from '@nutrien/minesight-utility-module';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';

import { defaultColumns as columns, PanelLogRow } from './ColumnDefs';
import { useStyles } from './RoomConditionCommentsTable.styles';

interface Props {
  data: PanelLogRow[];
}

const RoomConditionCommentTable: React.FC<Props> = ({ data }: Props) => {
  const classes = useStyles();

  const PAGE_SIZE = 5;

  const [{ pageIndex, pageSize }, setPagination] = useState<any>({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const sorting = [
    {
      id: 'modifiedOn',
      desc: true,
    },
  ];

  const table = useReactTable({
    data: data ?? defaultData,
    columns,
    pageCount: Math.ceil(data?.length / pageSize),
    state: { pagination, sorting },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false,
  });

  return (
    <>
      {table.getRowModel().rows.length > 0 ? (
        <div className={classes.tableContainer} data-testid="room-condition-comments-table">
          <table className={classes.tableRoot}>
            <colgroup>
              <col span={1} style={{ width: '10%' }} />
              <col span={1} style={{ width: '17%' }} />
              <col span={1} style={{ width: '19%' }} />
              <col span={1} style={{ width: '54%' }} />
            </colgroup>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr
                    key={row.id}
                    className={classes.tableRow}
                    data-testid={`room-condition-comments-table-row-${row.id}`}
                  >
                    {row.getVisibleCells().map(cell => {
                      switch (cell.column.columnDef.accessorKey) {
                        case 'log':
                          return (
                            <td key={cell.id}>
                              <Typography variant="body1">
                                <span
                                  style={{ whiteSpace: 'pre-wrap' }}
                                  dangerouslySetInnerHTML={{ __html: cell.getValue() }}
                                />
                              </Typography>
                            </td>
                          );
                        case 'panelDescription':
                          return (
                            <td key={cell.id}>
                              <Typography variant="caption" color="textSecondary">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Typography>
                            </td>
                          );
                        case 'modifiedOn':
                          return (
                            <td key={cell.id}>
                              <Typography variant="body1" color="textSecondary">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Typography>
                            </td>
                          );
                        case 'name':
                          return (
                            <td key={cell.id}>
                              <Typography variant="caption" color="textSecondary">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Typography>
                            </td>
                          );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.paginationContainer}
          >
            <Grid item>
              <IconButton
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                id="room-condition-comments-table-prev-page"
                data-testid="room-condition-comments-table-prev-page"
              >
                <Icons.ArrowLeftCircleFeather color="primary" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  table.nextPage();
                }}
                disabled={!table.getCanNextPage()}
                id="room-condition-comments-table-next-page"
                data-testid="room-condition-comments-table-next-page"
              >
                <Icons.ArrowRightCircleFeather color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Grid container justify="flex-start" className={classes.noDataMessage}>
          <Grid item>
            <Typography variant="body1">
              No {translate('room condition comments')} to show
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RoomConditionCommentTable;
