import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      padding: '3px 16px 13px 16px',
      gap: '16px',
      lineHeight: '23px !important',
      fontWeight: 400,
    },
    card: {
      padding: '16px',
    },
    container: {
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      marginTop: '16px',
    },
    verticalLineHeader: {
      width: '22px',
    },
    statusItemHeader: {
      display: 'flex',
      justifyContent: 'center',
    },
    statusItem: {
      borderLeft: '2px solid grey',
      display: 'flex',
      justifyContent: 'center',
    },
    item: {
      display: 'flex',
    },
    gridItem: {
      padding: '5px',
      display: 'flex',
    },
    itemSize: {
      fontSize: '14px !important',
    },
    selectedRow: {
      backgroundColor: '#2AAB3F14',
    },
    statusSelect: {
      borderWidth: '1px',
      border: 'solid',
      borderRadius: '40px',
      fontWeight: 'bold',
      textAlign: 'center',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      width: '90%',
      paddingLeft: '10%',
    },
    comment: {
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: 'Open Sans',
      lineHeight: '20px',
    },
    down: {
      backgroundColor: '#FFEBEE !important',
      color: '#121212',
      borderColor: '#F24965',
    },
    up: {
      backgroundColor: '#E1F5E4 !important',
      color: '#121212',
      borderColor: '#2AAB3F',
    },
    gridItemAction: {
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
    },
    assignmentSelect: {
      position: 'relative',
      padding: '5px !important',
      fontWeight: 600,
      fontFamily: 'Open Sans',
      textAlign: 'center',
      height: '32px',
      width: '100%',
    },
    readOnlyInput: {
      pointerEvents: 'none',
      cursor: 'default',
    },
    searchText: {
      width: '50% !important',
    },
    assignedCheckboxGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    assignedCheckbox: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: "'Open Sans'",
      lineHeight: '20px',
    },
    readOnlyStatus: {
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      color: 'black !important',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      width: 'fit-content',
      minWidth: 'fit-content',
      maxWidth: '100%',
    },

    readOnlyAssignment: {
      pointerEvents: 'none',
      color: 'white',
      textAlign: 'left',
      backgroundColor: 'transparent !important',
    },
    noDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '150px',
      width: '100%',
      alignItems: 'center !important',
    },
    noDataIcon: {
      height: '80px',
      width: '80px',
      marginBottom: '10px',
    },
    button: {
      width: 'fit-content !important',
      alignSelf: 'center',
    },
  }),
);

export default useStyles;
